/** @jsx jsx */
import { jsx, Box, Container, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import {
  Layout,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  CallToActionSchedule,
  HeroPrograms,
  PatientStoryGridCenters,
  ProgramsNewSideNav,
} from "../components/elements"

const ChildrenProgramConditions = ({ data, pageContext }) => {
  const page = data.datoCmsChildrensProgram
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const slug = pageContext.slug

  return (
    <Layout data-datocms-noindex>
      <HelmetDatoCms seo={page.seoMetaTags} />

      <HeroPrograms page={page} crumbs={crumbs} />

      <Container sx={{ px: "1em", pb: 5, pt:3 }}>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >

            <Box
              sx={{ paddingRight: [0, 0, 3], width: ["full", "full", "70%"] }}
            >
            <br />
            
            </Box>

            <Box sx={{ width: ["full", "full", "30%"] }}>
              <CallToActionSchedule />
            </Box>
          
          {/* Sidebar */}
          <Box
            as="aside"
            id="sidebar"
            sx={{
              bg: ["transparent", "transparent", "warmgray"],
              flexBasis: "sidebar",
            }}
            data-datocms-noindex
            width={["full"]}
          >
            <ProgramsNewSideNav page={page} />
            
          </Box>

          <Box
            as="main"
            sx={{
              flexGrow: 99999,
              flexBasis: 0,
              minWidth: 320,
            }}
          >
            {/* Content */}
            <Box as="section" id="content" data-datocms-noindex>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={{ width: ["full"], pl: [0, 0, 4] }}>
                  <Styled.h2 sx={{ mb: 3 }}>Conditions We Treat</Styled.h2>
                  <Text
                    sx={{ width: ["full"] }}
                    dangerouslySetInnerHTML={{
                      __html:
                        page.conditionsWeTreatNode.childMarkdownRemark.html,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <PatientStoryGridCenters slug={slug} />
    </Layout>
  )
}

export default ChildrenProgramConditions

export const query = graphql`
  query childrenProgramConditionsQuery($slug: String!) {
    datoCmsChildrensProgram(slug: { eq: $slug }) {
      id
      title
      slug
      findADocSpecialtyId
      conditionsWeTreat
      conditionsWeTreatNode {
        childMarkdownRemark {
          html
        }
      }
      description
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      diagnostics
      diagnosticsNode {
        childMarkdownRemark {
          html
        }
      }
      treatments
      treatmentsNode {
        childMarkdownRemark {
          html
        }
      }
      relatedServices
      relatedServicesNode {
        childMarkdownRemark {
          html
        }
      }
      clinicLocations
      preparingForYourAppointment
      downloads {
        id
        title
        document {
          url
        }
      }
      faqs {
        id
        question
        answerNode {
          childMarkdownRemark {
            html
          }
        }
      }
      downloadsIntroNode {
        childMarkdownRemark {
          html
        }
      }
      faqsIntroNode {
        childMarkdownRemark {
          html
        }
      }
      relatedServicesList {
        id
        title
        slug
      }
      relatedServicesNode {
        childMarkdownRemark {
          html
        }
      }
      clinicLocationsNode {
          childMarkdownRemark {
            html
          }
        }
       preparingForYourAppointmentNode {
          childMarkdownRemark {
            html
          }
        } 
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      logos {
        url
        logo {
          alt
          fluid(maxWidth: 250, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        id
      }
      seoMetaTags {
        tags
      }
    }
  }
`